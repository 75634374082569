import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
    Button,
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Icon, OverlineTitle, PreviewCard, UserAvatar } from "../../components/Component";
import coatImage from "../../../src/images/khoisan_images/coat.png";
import flagImage from "../../../src/images/khoisan_images/flag.png";
import kingImage from "../../../src/images/khoisan_images/king.png";
import card1 from "../../../src/images/khoisan_images/Card1.jpg";
import card2 from "../../../src/images/khoisan_images/Card2.jpg";
import QR from "../../../src/images/khoisan_images/QR.png";
import profileImage from "../../../src/images/khoisan_images/profile.jpg";
import { Card, CardBody } from "reactstrap";
import "../../assets/css/custom.css";
import DateTimePicker from "../components/forms/DateTimePicker";
import ReactDatePicker from "react-datepicker";
import Cropper from "../User/Cropper";
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";
import Oath from "./Oath";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers";


export default function NationalityCard() {
  const [startDate, setStartDate] = useState(new Date());
  const [numberString, setNumberString] = useState("6  0  0  3  2  2  1  1  1  0  0  0");
  const [activeTab, setActiveTab] = useState("1");
  const [isMobile, setIsMobile] = useState(false);
  const [fName, setFirstName] = useState("");
  const [lName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("Male"); 
  const [profileData, setProfileData] = useState([]);
  const [imgPreview, setImgPreview] = useState("");
  const authToken = getCookie('authToken');
  const [User, setUser] = useState(false);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/setting/get-profile`;
      const formData = new FormData();

      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          setProfileData(data.data.blogs|| []);
          setFirstName(data.data.blogs.first_name || "");
          setLastName(data.data.blogs.last_name || "");
          setEmail(data.data.blogs.email || "");
          setPhoneNumber(data.data.blogs.phone || "");
          setAddress(data.data.blogs.address || "");
          setDateOfBirth(data.data.blogs.date_of_birth || "");
          setGender(data.data.blogs.gender === "0" ? "Male" : "Female");
          setImgPreview(data.data.blogs.profile || "");
        } else {
          console.error("Error fetching profile data", data.message);
        }
      } else {
        console.error("Error fetching profile data", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/setting/get-list-users`;
        const response = await fetch(apiUrl, {
          headers: {
            authToken: getCookie("authToken"),
          },
          method: "GET",
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status) {
            setUser(data.data.users.Oath_doc);
          } else {
            console.error("Error fetching user data", data.message);
          }
        } else {
          console.error("Error fetching user data", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };
    fetchUserData();
}, [authToken]);

  const handleUpdate = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/setting/update-profile`;
      const formData = new FormData();
      formData.append("first_name", fName);
      formData.append("last_name", lName);
      formData.append("email", email);
      formData.append("phone", phoneNumber);
      formData.append("address", address);
      formData.append("gender", gender === "Male" ? "0" : "1");
      formData.append("date_of_birth", dateofbirth);
    
    if (imgPreview && imgPreview.startsWith("blob:")) {
        const blob = await fetch(imgPreview).then((res) => res.blob());
        formData.append("profile", blob);
      }

      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          toast.success("Profile updated successfully");
          setTimeout(() => {
          }, 3000);
          fetchProfileData();
        } else {
            if (data?.errors) {
                toast.error(data?.errors[0]);
            } else {
                toast.error(data.message);
            }
        }
      } else {
        console.error("Error updating profile", response.statusText);
      }
    } catch (error) {
      console.error("Update error:", error);
    }
  };


  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // Set the start date to the first day of the current month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    setStartDate(firstDayOfMonth);
  }, []);

  const earliestDate = new Date(1900, 0, 1); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const firstName = profileData.first_name || "";
  const lastName = profileData.last_name || "";
  const identy_number = profileData.identy_number || "";
  const date_of_birth = profileData.date_of_birth || "";
  const formattedIdentityNumber = profileData.identy_number ? profileData.identy_number.split('').join('  ') : '';


  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    return `${day}-${month}-${year}`;
  };

  const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    } else {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}-${month}-${year}`;
    }
  };
  

  return (
    <div>
      <PreviewCard className="mt-5 ms-5 me-5">
        <div className="">
          <Nav tabs className="mt-n3 border-0">
            <NavItem className="mt-2">
              <NavLink
                tag="a"
                href="#tab"
                className={`crimson-text nav-link`}
                style={{ cursor: "pointer", borderBottom: activeTab === "1" ? "3px solid #8C0821" : "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                <Icon name="user" className="fs-6" /> <span className="fs-6">Profile</span>
              </NavLink>
            </NavItem>
            <NavItem className="mt-2">
              <NavLink
                tag="a"
                href="#tab"
                className={`crimson-text nav-link`}
                style={{ cursor: "pointer", borderBottom: activeTab === "2" ? "3px solid #8C0821" : "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                <Icon name="cards" className="fs-6" /> <span className="fs-6">Nationality Card</span>
              </NavLink>
            </NavItem>
            <NavItem className="mt-2">
              <NavLink
                tag="a"
                href="#tab"
                className={`crimson-text nav-link ${User ? '' : 'd-none'}`}
                style={{ cursor: "pointer", borderBottom: activeTab === "3" ? "3px solid #8C0821" : "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("3");
                }}
              >
                <Icon name="file-doc" className="fs-6" /> <span className="fs-6">Oath</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="gy-4">
                <h5>Personal Information</h5>
                <Col sm="6">
                    <Cropper  profileImage={imgPreview} setImgPreview={setImgPreview}/>
                </Col>
                <Col sm="6">
                    <Row>
                        <Col sm="6">
                            <div className="form-group">
                                <Label htmlFor="fname" className="form-label">
                                First Name<span className="text-danger"> *</span>
                                </Label>
                                <div className="form-control-wrap">
                                    <input className="form-control" type="text" id="fname" name="first_name" placeholder="Enter First Name" value={fName} 
                                    onChange={(e) => setFirstName(e.target.value)}/>
                                </div>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="form-group">
                                <Label htmlFor="lname" className="form-label">
                                Last Name<span className="text-danger"> *</span>
                                </Label>
                                <div className="form-control-wrap">
                                <input className="form-control" type="text" id="lname" name="last_name" placeholder="Enter Last Name" value={lName} 
                                    onChange={(e) => setLastName(e.target.value)}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6" className="pt-2">
                            <div className="form-group">
                                <Label htmlFor="email" className="form-label">
                                Email<span className="text-danger"> *</span>
                                </Label>
                                <div className="form-control-wrap">
                                    <input className="form-control" type="text" id="email" name="email" placeholder="Enter Email" value={email} 
                                    onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" className="pt-2">
                            <div className="form-group">
                                <Label htmlFor="phoneno" className="form-label">
                                    Phone Number<span className="text-danger"> *</span>
                                </Label>
                                <div className="form-control-wrap">
                                    <input className="form-control" type="number" id="phoneno" name="phone" placeholder="Enter Phone Number" value={phoneNumber} 
                                    onChange={(e) => setPhoneNumber(e.target.value)} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group pt-2">
                        <Label htmlFor="cbirth" className="form-label">
                        Date of Birth<span className="text-danger"> *</span>
                        </Label>
                        <ReactDatePicker
                        id="startDate"
                        selected={dateofbirth ? new Date(dateofbirth) : null} 
                        onChange={(date) => {
                            setDateOfBirth(date); 
                          }}
                        dateFormat="dd-MM-yyyy" 
                        minDate={earliestDate} 
                        maxDate={new Date()}
                        className="form-control"
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker  />
                        </LocalizationProvider> */}
                    </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="address" className="form-label">
                      Address
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="no-resize form-control"
                        type="textarea"
                        id="address"
                        defaultValue="Enter Your Address" value={address} 
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                    <div className="form-group">
                        <Label htmlFor="status" className="form-label">
                            Gender<span className="text-danger"> *</span>
                        </Label>
                        <div className="custom-control custom-radio ms-5">
                            <input
                            type="radio"
                            id="customRadio1"
                            name="customRadio"
                            checked={gender === "Male"} 
                            onChange={() => setGender("Male")} 
                            className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="customRadio1">
                            Male
                            </label>
                        </div>
                        <div className="custom-control custom-radio ms-5">
                            <input
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            checked={gender === "Female"} 
                            onChange={() => setGender("Female")} 
                            className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="customRadio2">
                            Female
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-control-wrap theme-color">
                            <Button  className="" color="" size="lg" onClick={handleUpdate}>
                                Update
                            </Button>
                        </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <>
                <div className="row justify-content-center mt-2">
                  <div className={isMobile ? "col-md-12" : "n-card d-flex mb-5"}>
                    <div className={`col-md-5 ${isMobile ? "" : "mt-5"} ms-auto`}>
                      <Card
                        className="h-100 d-block b-image rounded-5"
                        inverse
                        color="primary"
                        style={{
                          backgroundImage: `url(${card1})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <CardBody className="card-inner">
                          <div className="row">
                            <div className="col-2">
                              <img src={coatImage} className="" alt="coat image" />
                            </div>
                            <div className="col-8">
                              <h2 className="cursive-text">The Sovereign State of Good Hope</h2>
                              <h5 className="text-center fw-bold card-heading">NATIONAL IDENTITY CARD</h5>
                            </div>
                            <div className="col-2">
                              <img src={flagImage} className="flag-image" alt="flag image" />
                            </div>
                          </div>
                          <div className="row mt-2 my-1">
                            <div className="col-9">
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>NAME</p>
                                <span className={isMobile ? "fw-bold col-7 mb-2" : "fw-bold col-7"}> : {firstName} {lastName}</span>  
                                {/* Khoebaha Calvin Devnver John */}
                              </p>
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>GENDER</p>
                                <span className="fw-bold col-7"> : {profileData.gender === '0' ? 'Male' : 'Female'}</span>
                              </p>
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>IDENTITY NUMBER</p>
                                <span className="fw-bold col-7"> : {identy_number}</span>
                              </p>
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>DATE OF BIRTH</p>
                                <span className="fw-bold col-7"> : {formatDate1(date_of_birth)}</span>
                              </p>
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>COUNTRY OF BIRTH</p>
                                <span className="fw-bold col-7"> : SSGH</span>
                              </p>
                              <p className="text-black row card-info">
                                <p className={isMobile ? "col-5" : "info col-5 mb-0"}>STATUS</p>
                                <span className="fw-bold col-7"> : citizens</span>
                              </p>
                            </div>
                            <div className="col-3">
                              {/* <img src={kingImage} className="" alt="king image" /> */}
                              {profileData.profile ? (
                                <img src={profileData.profile} alt="Profile" className="img-fluid" />
                                ) : (
                                <img src={profileImage} alt="Default Profile" className="img-fluid" />
                                )}
                            </div>
                          </div>
                          <div className="d-flex justify-between pt-5">
                            <div className="justify-content-between d-flex">
                              <div className="item-count"></div>
                              <div className="item-count1 ms-2"></div>
                              <div className="item-count2 ms-2"></div>
                            </div>
                            <div className="justify-content-between d-flex">
                              <div className="item-count ms-2"></div>
                              <div className="item-count1 ms-2"></div>
                              <div className="item-count2 ms-2"></div>
                            </div>
                            <div className="justify-content-between d-flex">
                              <div className="item-count"></div>
                              <div className="item-count1 ms-2"></div>
                              <div className="item-count2 ms-2"></div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                    <div className={`col-md-5 mt-5 ${isMobile ? "" : "ms-5"} me-auto back-section`}>
                      <Card
                        className="h-100 d-block b-image rounded-5"
                        inverse
                        color="primary"
                        style={{
                          backgroundImage: `url(${card2})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <CardBody className="card-inner">
                          <div className="row">
                            <div className="col-2">
                              <img src={coatImage} className="" alt="coat image" />
                            </div>
                            <div className="col-8">
                              <h2 className="cursive-text">The Sovereign State of Good Hope</h2>
                              <h5 className="text-center fw-bold card-heading">NATIONAL IDENTITY CARD</h5>
                            </div>
                            <div className="col-2">
                              <img src={flagImage} className="flag-image" alt="flag image" />
                            </div>
                          </div>
                          <div className="row mt-4 mb-2">
                            <div className="col-12 text-center">
                                <p className="text-black fs-4">{formattedIdentityNumber} </p>
                              {/* <input type="text" className="text-center" value={formattedIdentityNumber} readOnly /> */}
                            </div>
                          </div>
                          <div className="row mt-5 mb-2">
                            <div className="col-12 text-center">
                              <img src={QR} className="qr-image" />
                            </div>
                          </div>
                          <div className="row mt-4 mb-2">
                            <div className="col-12">
                              <p className="text-black fw-bold float-end card-date pt-5">DATE OF ISSUE : 08-06-2018</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </>
            </TabPane>
            <TabPane tabId="3">
              <>
                <Oath />
              </>
            </TabPane>
          </TabContent>
        </div>
      </PreviewCard>
    </div>
  );
}

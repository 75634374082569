import React, { useState, useRef, useEffect  } from 'react'
import "../../assets/css/custom.css";
import { getCookie } from '../../utils/Utils';
import logo from '../../../src/images/khoisan_images/logo.png';
import bimage from '../../../src/images/khoisan_images/sovereign-favicon.png';
import "../../../src/assets/css/certificate.css";

export default function Oath() {
    const authToken = getCookie('authToken');
    const [User, setUser] = useState(false);
    const [OathName, setOathName] = useState(null);
    const [Witness, setWitness] = useState(null);
    const [Place, setPlace] = useState(null);
    const [Signature, setSignature] = useState(null);
    const [WitnessSignature, setWitnessSignature] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/setting/get-list-users`;
            const response = await fetch(apiUrl, {
              headers: {
                authToken: getCookie("authToken"),
              },
              method: "GET",
            });
    
            if (response.ok) {
              const data = await response.json();
              if (data.status) {
                setUser(data.data.users.Oath_doc);
                setOathName(data.data.users.oath_name);
                setSignature(data.data.users.signature);
                setWitnessSignature(data.data.users.witness_signature);
                setPlace(data.data.users.place);
                setWitness(data.data.users.witness);
              } else {
                console.error("Error fetching user data", data.message);
              }
            } else {
              console.error("Error fetching user data", response.statusText);
            }
          } catch (error) {
            console.error("Fetch error:", error);
          }
        };
        fetchUserData();
    }, [authToken]);
    
    return (
        <>
            <section className="section-oath section mt-5">
                <div className="container certify pt-1">
                    <div className="row certificate-container justify-content-center">
                        <div className="col-md-12 certificate">
                            <img src={bimage} className='certificate-img' style={{left:'30%', top:'25%', zIndex: '-1', opacity: '0.3', position: 'absolute'}}></img>
                            <div className='row'>
                                <div className='col-md-10 mt-5'>
                                    <div className="water-mark-overlay"></div>
                                    <div className="certificate-header p-3">
                                        <img src={logo} className="logo" alt="" />
                                    </div>
                                </div>
                            </div>
                        
                            <div className='row justify-content-center'>
                                <div className='col-md-6'>
                                    <h2 className='text-center playball-text' style={{fontFamily: "'Brush Script MT', cursive"}}>The Oath of Allegiance</h2>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-12 text-center'>
                                    <p className='crimson-text'>I witness myself before god, and in the presence of his spirit, country, countrymen, and king.That I <span className='fw-bold fs-6'>{OathName}</span></p>
                                    {/* <p className='text-center fw-bold crimson-text' id="oath-name">{OathName}</p> */}
                                    <p className='crimson-text'>do hereby solemnly swear/commit my allegiance to the Sovereign State of Good Hope,The king and the royal house.</p>
                                    <p className='crimson-text'>I swear my allegiance to all citizens of the Sovereign State of Good Hope, the constitution, and the principle of the rule of law.</p>
                                    <p className='crimson-text'>I commit myself to the belief in the fundamental respect and universal dignity of every human being.</p>
                                    <p className='crimson-text'>I will defend my nation and freedoms as bestowed on me from all enemies, foreign and domestic.</p>
                                    <p className='fw-bold crimson-text'>I make this oath of free will binding on my conscience, to serve the nation, the king and the</p>
                                    <p className='fw-bold crimson-text'>Royal house of the Sovereign State of Good Hope, before all others.</p>
                                    <p className='text-center fw-bold crimson-text'>So Help Me God</p>
                                </div>
                            </div>
                            <div className='row mt-3 justify-content-center'>
                                <div className='col-md-6 col-6'>
                                    <p className='text-center fw-bold crimson-text'>Place : <span className='crimson-text'>{Place}</span></p>
                                </div>
                                <div className='col-md-6 col-6'>
                                    <p className='text-center fw-bold crimson-text'>Witness : <span className='crimson-text'>{Witness}</span></p>
                                </div>
                            </div>
                            <div className='row mt-5 justify-content-center'>
                                <div className='col-md-6 col-6 text-center'>
                                    {Signature ? (
                                        <>
                                            <img
                                                src={Signature}
                                                alt="Signature"
                                                className="signature-img h-50"
                                            />
                                            <p className="text-center fw-bold crimson-text">__________________________</p>
                                        </>
                                    ) : (
                                        <span className="text-center fw-bold crimson-text">___________________________</span>
                                    )}
                                    <p className='text-center crimson-text fw-bold'>Oath Signature</p>
                                </div>
                                <div className='col-md-6 col-6 text-center'>
                                    {WitnessSignature ? (
                                        <>
                                            <img
                                                src={WitnessSignature}
                                                alt="Signature"
                                                className="signature-img h-50"
                                            />
                                            <p className="text-center fw-bold crimson-text">_________________________</p>
                                        </>
                                    ) : (
                                        <span className="text-center fw-bold crimson-text">___________________________</span>
                                    )}
                                    <p className='text-center crimson-text fw-bold'>Witness Signature</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

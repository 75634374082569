import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import AppHeader from "./header/AppHeader";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import Cookies from "js-cookie";

import { useTheme } from "./provider/Theme";

const Layout = ({ title, app, ...props }) => {
  const [authChecking, setAuthChecking] = useState(true);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
      Cookies.remove("currentURL", { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    }
  }, []);
  if (authChecking) return <>Redirecting to authentication</>;

  return (
    <>
      <Head title={!title && "Loading"} />
      <AppRoot className="npc-apps apps-only">
        <Appbar />
        <AppMain>
          {/* {theme.sidebarMobile && <Sidebar fixed />} */}
          <AppWrap>
            <AppHeader app={app} fixed />
            <Outlet />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;

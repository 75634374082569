const menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "user",
    text: "User Profile",
    link: "/user-profile",
    dashboard: true,
  },
];
export default menu;

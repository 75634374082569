import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// import Homepage from "../pages/Homepage";

import ChartWidgets from "../pages/components/widgets/ChartWidgets";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import LayoutApp from "../layout/Index-app";


import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import NationalityCard from "../pages/User/NationalityCard";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutApp app={{icon:"dashboard", theme:"bg-purple-dim", text: "Dashboard"}} />}>

          <Route index element={<NationalityCard />}></Route>
          <Route path="/user-profile" element={<NationalityCard />}></Route>
        </Route>

        <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
          
            <Route path="errors">
              <Route path="404-modern" element={<Error404Modern />}></Route>
              <Route path="404-classic" element={<Error404Classic />}></Route>
              <Route path="504-modern" element={<Error504Modern />}></Route>
              <Route path="504-classic" element={<Error504Classic />}></Route>
            </Route>
            <Route path="*" element={<Error404Modern />}></Route>
            
        </Route>
      </Routes>
  );
};
export default Pages;
